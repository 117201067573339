import React from 'react'
import { string, oneOfType, node } from 'prop-types'
import './style.less'

const Card = ({ title, description, button }) => {
  return (
    <div className="u-mb-xs u-text-center card">
      <h3 className="u-text-p3">{title}</h3>
      <p className="u-text-gray-600 u-text-p5">{description}</p>
      {button}
    </div>
  )
}

Card.propTypes = {
  title: oneOfType([string, node]),
  description: oneOfType([string, node]),
  button: node,
}

export default Card
