import React from 'react'

import { Link, navigate } from 'gatsby'
import HelpDeskLogo from '../../assets/icons/helpdesk.svg'
import HDBlob1 from '../../assets/icons/hd-sparkle-blob-1.svg'
import HDBlob2 from '../../assets/icons/hd-sparkle-blob-2.svg'
import HandshakeIcon from '../../assets/icons/handshake.svg'
import TeamIcon from '../../assets/icons/team.svg'
import MoneyBagIcon from '../../assets/icons/money-bag.svg'
import BrowserIcon from '../../assets/icons/browser.svg'
import FreeDollarIcon from '../../assets/icons/free-dollar.svg'

import { SEO, Image, Tour, Benefits } from '../../components'

import './mp-ext-devs.less'

import OgImage from '../../assets/images/hd-in-mp-og.png'
import Card from '../../components/Card/Card'
import Layout from '../../layout/layout'

const LiveCoding = () => {
  return (
    <Layout>
      <SEO
        title="Join the launch celebration and build an app for HelpDesk"
        description="HelpDesk has officially joined the Marketplace and is now open for your app development. Go to the Developer Console and start building."
        image={OgImage}
      />
      <div className="u-bg-black-2 u-Pt-xl u-Pb-xl">
        <section className="o-container u-Mb-md md:u-mb-0 section__get-started">
          <div className="u-mb-xs">
            <h1 className="u-text-p2">
              Be the first to create apps for the HelpDesk Marketplace
            </h1>
            <p className="u-text-gray-600 u-text-p5">
              HelpDesk has joined the Marketplace, and new opportunities for app
              development and monetization are waiting for you.
            </p>

            <Link
              to="/app-ideas?hd"
              className="c-btn u-w-fit u-mt-xs v--livechat u-text-white u-text-p6-bold"
            >
              Explore ideas
            </Link>
          </div>
          <Image type="hdHero" wrapperStyles={{ width: '480px' }} />
        </section>
      </div>
      <section className="u-mb-sm u-Pt-sm section__celebration">
        <h2 className="u-text-p3 u-text-center">
          Be part of our HelpDesk Marketplace <br />
          <span className="label-green">Launch Celebration!</span>{' '}
          <span role="img" aria-label="emoji">
            🎉
          </span>
        </h2>
        <p className="u-text-p5 u-Mb-xl u-text-center">
          Submit your app for review before <strong>March 10, 2023</strong> to
          win a spot
          <br /> in a brand-new Marketplace before others.
        </p>
      </section>
      <section className="o-container u-Mb-xl u-flex u-justify-center card-section">
        <Card
          title={
            <div className="card-title">
              <HelpDeskLogo />
              <span>HelpDesk</span>
            </div>
          }
          description="A simple ticketing system full of growth potential."
          button={
            <a
              href={process.env.GATSBY_CONSOLE_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="c-btn u-w-fit u-mt-xs v--livechat u-text-white u-text-p6-bold"
            >
              Create app right now
            </a>
          }
        />
        <div className="blur-green" />
      </section>
      <section className="u-Mb-xl md:u-Px-xl hd-tour">
        <h2 className="u-text-p3 u-Mb-md u-text-center">
          Get an idea of <span className="label-green">how HelpDesk works</span>{' '}
          <br className="mobile-break" />
          before coding{' '}
          <span role="img" aria-label="emoji">
            🤔
          </span>
        </h2>
        <Tour
          inverse
          noMargin
          HD
          imgStyles={{ objectFit: 'contain', marginTop: '-20px' }}
          features={[
            {
              image: 'hdTour1',
              alt: 'HelpDesk various channels',
              title: 'Handling various channels',
              description:
                'Users are free to integrate different communication channels so all messages reach one convenient dashboard.',
            },
            {
              image: 'hdTour2',
              alt: 'HelpDesk multiple teams management',
              title: 'Managing multiple teams',
              description:
                "Team managers can easily track and improve the team member's performance when creating customer relationships.",
            },
            {
              image: 'hdTour3',
              alt: 'HelpDesk daily activities automation',
              title: 'Automating daily activities',
              description:
                'Users can use smart features such as automated workflows or canned responses to speed up the troubleshooting process.',
            },
          ]}
        />
        <HDBlob1 className="hd-tour-blob-1" />
        <span className="hd-tour-blob-ellipse" />
        <div className="u-text-center u-Mt-md">
          <a
            href={process.env.GATSBY_HELPDESK_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="c-btn u-w-fit u-mt-xs v--livechat u-text-white u-text-p6-bold"
          >
            Learn more about HelpDesk
          </a>
        </div>
      </section>
      <section className="o-container u-Mb-xl section__worth-it section-mobile">
        <h2 className="u-text-p3 u-Mb-md u-text-center">
          Why is{' '}
          <span className="label-green">joining the Developer Program</span>
          <br className="mobile-break" /> worth it?{' '}
          <span role="img" aria-label="emoji">
            👌
          </span>
        </h2>
        <Benefits
          HD
          content={[
            {
              label: 'In the spotlight',
              description:
                'Distribute your newly developed apps to the tens of thousands of users who browse the Marketplace every week.',
            },
            {
              label: 'Three helpful toolkits',
              description:
                'Take advantage of the research, development, and distribution toolkits to streamline your app development process.',
            },
            {
              label: 'Support and assistance',
              description:
                'Benefit from our expertise in technical support, business advisory, functional testing, or design review.',
            },
            {
              label: 'In tune with your lifestyle',
              description: (
                <>
                  Work on your app projects as you wish, using the convenient{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.GATSBY_CONSOLE_URL}
                  >
                    Developer Console
                  </a>{' '}
                  and Building Blocks. Creative freedom is a big deal here!
                </>
              ),
            },
          ]}
        />
        <div className="u-text-center">
          <Link
            to="/developer-program/offer"
            className="c-btn u-w-fit v--livechat u-text-white u-text-p6-bold"
          >
            Browse Developer Platform offer
          </Link>
        </div>
      </section>

      <section className="u-bg-black-900 u-Mb-xl u-Py-md section__development section-mobile">
        <h2 className="u-text-p3 u-Mb-md u-text-center">
          Show off your development skills
          <br /> and{' '}
          <span className="label-green">build apps for HelpDesk </span>
          <span role="img" aria-label="emoji">
            ⚙️
          </span>
        </h2>
        <Benefits
          HD
          content={[
            {
              label: 'New product, same rules',
              description:
                'Be the very first to bring your HelpDesk app idea from the ground up. Get excellent visibility for your solution in the Marketplace.',
              icon: BrowserIcon,
            },
            {
              label: 'Guaranteed sales commission',
              description:
                "Build your independent income stream with us. There's no fine print here — just clear rules on how you can monetize your app idea.",
              icon: MoneyBagIcon,
            },
            {
              label: 'Unreached audiences',
              description:
                'Simplify the daily work of more than 3,000 support agents with your one-of-a-kind app for HelpDesk.',
              icon: TeamIcon,
            },
            {
              label: 'Assured promo campaign',
              description:
                'Build awareness and run a promo campaign for our users as the final step in development. You can count on our support!',
              icon: HandshakeIcon,
            },
          ]}
        />
        <div className="u-text-center u-Mb-md">
          <a
            href="https://developers.livechat.com/docs/getting-started/helpdesk-apps"
            className="c-btn u-w-fit v--livechat u-text-white u-text-p6-bold"
          >
            Browse app creation guide
          </a>
        </div>
      </section>
      <section className="u-Pb-md md:u-Px-xl section__ideas">
        <div className="o-container">
          <h2 className="u-text-p3 u-Mb-md u-text-center">
            <span className="label-green">Monetize</span> one of our
            <br /> ready-made ideas{' '}
            <span role="img" aria-label="emoji">
              💡
            </span>
          </h2>
          <div>
            <div
              onClick={() => navigate('/app-ideas?hd')}
              className="u-hover-lift u-hover-pointer"
            >
              <div className="u-bg-black-7 u-text-center u-p-2xl u-rounded u-shadow u-h-full u-flex u-flex-col u-justify-between">
                <div className="u-Pb-xs u-text-center">
                  <FreeDollarIcon />
                </div>
                <div>
                  <h3 className="u-text-p4 u-font-bold">
                    Building an app just got easier
                  </h3>
                  <h4 className="u-text-gray-600 u-text-p6">
                    Choose from our idea list and start building your own app
                    now.
                  </h4>
                </div>
                <div
                  onClick={() => navigate('/app-ideas?hd')}
                  className="u-Mt-xs c-link u-text-p6-bold u-text-blue"
                >
                  Browse app ideas
                </div>
              </div>
            </div>
          </div>
        </div>
        <HDBlob2 className="hd-tour-blob-2" />
        <div className="u-Mb-xl u-Pt-xl u-text-center developers-cta">
          <h2 className="u-text-p3 u-Mb-md">
            Join our <span className="label-green">Developers Community</span>
            <span role="img" aria-label="emoji">
              👋
            </span>
          </h2>
          <p className="u-text-p5">
            Get the encouragement you need to kick-start your app project! Ask
            the community members for guidance and exchange knowledge.
          </p>
          <a
            href="https://discord.gg/gRzwSaCxg4"
            target="_blank"
            rel="noopener noreferrer"
            className="c-btn u-w-fit u-Mt-xs u-mt-xs v--livechat u-text-white u-text-p6-bold"
          >
            Talk to developers on Discord
          </a>
        </div>
      </section>

      <section className="o-container u-Mb-md md:u-mb-0 section__hero">
        <div>
          <h3 className="u-text-p4 u-text-quote u-maxw-5 u-mx-auto md:u-ml-0 u-text-center md:u-text-left">
            HelpDesk SDK is really{' '}
            <span className="u-text-mark u-text-nowrap">powerful</span>. You can
            achieve a lot with the methods provided there.
          </h3>
          <p className="u-text-p6 u-text-center md:u-text-left">
            <strong>Jakub Sikora</strong>, CEO at Sikora Works
          </p>
        </div>
        <div className="u-mb-xs">
          <Image
            type="jakubSikora2"
            alt="Jakub Sikora, Sikora Works"
            style={{ width: '256px' }}
          />
        </div>
      </section>
      <div className="u-My-md u-text-center">
        <a
          href={process.env.GATSBY_CONSOLE_URL}
          className="c-btn u-w-fit u-mt-xs v--livechat u-text-white u-text-p6-bold"
        >
          Join Developer Program
        </a>
      </div>
    </Layout>
  )
}

export default LiveCoding
